import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/styles/reset.min.css' // global css
import './assets/styles/base.css' // global css
import './assets/font/inter.css' // font
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
