import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const Home = () => import("../views/home.vue");




const routes = [
  {
    path: "/",
    name: "home",
    component:Home,
  },
  {
    path: "/home",
    name: "home",
    component:Home,
  },
 
];

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

export default router;
