<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
html {
  background-color: #182B2E;
  color: #fff;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-attachment: fixed;




}
#app {

  height: 100vh;
 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  -webkit-overflow-scrolling: touch;

}
*{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 4px;

}

::-webkit-scrollbar-track {
  background: rgba(24, 46, 29, 1);
}

::-webkit-scrollbar-thumb {
  background: rgba(41, 82, 60, 1)
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #1a1c2f;
}
</style>
